import React from "react";
import "jquery-ui-dist/jquery-ui";
import HomePage from "./components/HomePage";
import { HelmetProvider } from 'react-helmet-async';


function App() {

	return (
		<>
			<HelmetProvider>
				<HomePage />
			</HelmetProvider>
		</>
	);
}

export default App;
