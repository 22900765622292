import { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import Getintouch from './Getintouch'
import Metatag from './Metatag'
import { Link } from 'react-router-dom'
import { getAPIData } from '../Services/index'

const apiNews = `${process.env.REACT_APP_BASE_URL}/news`
const apiNewsGeneral = `${process.env.REACT_APP_BASE_URL}/newsGeneral`
const baseUrl = `${process.env.REACT_APP_IMAGE_URL}`

function Newspage() {
    const [loading, setLoading] = useState(true);
    const [newsData, setNewsData] = useState([]);
    const [newsGeneralData, setNewsGeneralData] = useState([]);

    const useFetchData = (setData, data) => {
        return async () => {
            try {
                setLoading(true);
                const resp = await getAPIData(data);
                setData(resp?.data?.data);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
    };

    const fetchNews = useFetchData(setNewsData, { url: apiNews });
    const fetchNewsGeneral = useFetchData(setNewsGeneralData, { url: apiNewsGeneral });

    useEffect(() => {
        fetchNews();
        fetchNewsGeneral();
    }, [fetchNewsGeneral, fetchNews]);

    newsData?.map((element, i) => {
        const date = new Date(element.date);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        element.formattedDate = formattedDate;
        return element;
    });

    return (
        <>
            <Metatag
                link="news"
                title="AU Real Estate News: Stay Updated on Latest Developments"
                description="Get the latest updates and developments from AU Real Estate. Stay informed about our initiatives and progress in the real estate industry."
            />
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                {/* <video src='/websiteimages/banner-video.mp4' autoPlay loop muted /> */}
                <img src='/websiteimages/news-media-banner.png' alt='' />
                <div className='aurealestate-content'>
                    <h1 dangerouslySetInnerHTML={{ __html: newsGeneralData?.[0]?.banner_title }}></h1>
                </div>
            </div>
            <div className='newsmain'>
                <div className='breadcrum-div breadcrumdiv'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{newsGeneralData?.[0]?.metadesc}</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2>{newsGeneralData?.[0]?.title}</h2>
                            <ul>
                                {newsData?.map((element, i) => (
                                    <li key={i}>
                                        <Link rel="noreferrer" to={element?.url} target='_blank' className='newsmediadivlink'>
                                            <div className='newsmediadiv'>
                                                <div className='newsmediadivleft'>
                                                    <img src={`${baseUrl}${element?.news_image}`} alt={element?.news_imagealt} />
                                                </div>
                                                <div className='newsmediadivright'>
                                                    <h3>{element?.title}</h3>
                                                    <p className='date'>{element?.formattedDate}</p>
                                                    <div dangerouslySetInnerHTML={{ __html: element?.description }}></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Newspage