import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Header from './Header';
import Footer from './Footer';
import Getintouch from './Getintouch';
import Metatag from './Metatag';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import "./mainpage.css"
import "./mainresponsive.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

import { auphilosopy, aubussiness, uacorporate, aufoundermessage, auvalues, autestimonial } from '../local-api/index';

function Mainpage() {
	const [muteBannerVideo, setMuteBannerVideo] = useState(true);
	const [bannerVideo, setBannerVideo] = useState(false);
	const [videoplay, setVideoPlay] = useState(null);
	const [show, setShow] = useState(`${auvalues?.accordionImg[0]?.img}`);
	const [width, setWidth] = useState(0);
	const [isMobile, setIsMobile] = useState(false);
	const [appendVideo, setAppendVideo] = useState(false);
	const elementRef = useRef(null)
	const bannerRef = useRef(null)

	useEffect(() => {
		setWidth(window.innerWidth);
		setIsMobile(width <= 768);

		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, [width]);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {

			if (entry.isIntersecting && !appendVideo) {
				setAppendVideo(entry.isIntersecting);
			}
		}, {
			threshold
				: 0.5,
		});
		if (elementRef.current) {
			observer.observe(elementRef.current);
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setBannerVideo(true);
		}, 3000)
	}, []);


	const handleVideoClick = () => {
		setVideoPlay(true);
	}

	const handleAccordionClick = (index) => {
		const imagesUrl = auvalues?.accordionImg.map((element) => {
			return element.img
		})
		setShow(imagesUrl[index]);
	};

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	const schemaData = {
		"@@context": "https://schema.org",
		"@type": "Organization",
		"name": "AU Realstate",
		"url": "https://aurealestate.in/",
		"logo": "https://aurealestate.in/websiteimages/au-logo-black.png",
		"contactPoint": {
			"@type": "ContactPoint",
			"telephone": "98999 92222",
			"contactType": "sales",
			"areaServed": "IN",
			"availableLanguage": ["en", "Hindi"]

		}
	};

	return (
		<React.Fragment>
			<Metatag
				link="/"
				title="Real Estate Investment in India: Ghaziabad Properties | AU"
				description="Explore lucrative real estate investment opportunities in Ghaziabad, India. Discover prime properties for investment with Aurea Real Estate."
			/>
			<HelmetProvider>
				<Helmet>
					<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
				</Helmet>
			</HelmetProvider >
			<Header />
			<Getintouch />
			<div className='aurealestate-banner' ref={bannerRef}>
				<h1 style={{ position: 'absolute', zIndex: -100 }}>AU Real Estate Projects</h1>
				{bannerVideo == false ?
					<img ref={bannerRef} src='/websiteimages/bannerimg.jpg' alt='banner image' /> :
					<video className='themeplay' src='/websiteimages/banner-video.mp4' alt="banner-video" autoPlay loop muted={muteBannerVideo} priority={'true'}></video>
				}
				{muteBannerVideo ?
					<div className='videoplaydiv'>
						<img src='/websiteimages/volume-off-indicator.png' alt='volume-off' onClick={() => setMuteBannerVideo(false)} height={40} width={40} />
					</div> :
					<div className='videoplaydiv'>
						<img src='/websiteimages/speaker-filled-audio-tool.png' alt='volume-On' onClick={() => setMuteBannerVideo(true)} height={40} width={40} />
					</div>
				}
			</div>
			<div className='aurealestate-auphilosopy'>
				<div className='aurealestate-auphilosopyline'>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<h2>AU Philosophy</h2>
							<p>AU Group's philosophy revolves around integrity, innovation, and unwavering dedication to clients. We believe in transparent practices, superior service, and fostering a sense of pride in ownership. Our commitment to these values defines our path towards excellence in the real estate industry.</p>
						</div>
					</div>
				</div>
				<div style={{ width: '100%', aspectRatio: '600/295' }}>
					<img src='/websiteimages/au-philosopy-img.webp' alt='AU Philosophy' width='600' height='295' />
				</div>
			</div>

			{/* On scroll */}
			<div className='aurealestate-bussiness' data-aos="fade-up" data-aos-duration="500" >
				<div className='container'>
					<h2>{aubussiness?.heading}</h2>
					<div className='row'>
						<div className='col-lg-12'>
							<div className="aurealestate-bussinessimgmaintop">
								{aubussiness?.bussinessimgmaintop?.map((element, i) => {
									return <div key={i} className="aurealestate-bussinessimgdiv">
										<img src={element?.src} alt={element?.alt} />
										<div className="aurealestate-bussinesscontentdiv">
											<a href={element?.url} target='_blank'>
												<img src={element?.logoSrc} alt={element?.logoAlt} loading='lazy' />
												<p>{element?.text}</p>
											</a>
										</div>
									</div>
								})}
							</div>
							<div className="aurealestate-bussinessimgmainbottom">
								{aubussiness?.bussinessimgmainbottom?.map((element, i) => {
									return <div key={i} className="aurealestate-bussinessimgdivbottom">
										<img src={element?.src} alt={element?.alt} loading='lazy' />
										<div className="aurealestate-bussinesscontentdivbottom">
											<Link to={element?.url} >
												<img src={element?.logoSrc} alt={element?.logoAlt} loading='lazy' />
												<p>{element?.text}</p>
											</Link>
										</div>
									</div>
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='exceeding-expectations' data-aos="fade-up" data-aos-duration="500">
				<h2>{uacorporate?.heading}</h2>
				<div className="video-div" data-aos="fade-up">
					<div id="ytvideoclick" className="ytvideo" data-video={uacorporate?.dataVideo}
						style={{ backgroundImage: `url(${uacorporate?.backgroundImg})` }}
						onClick={() => handleVideoClick()}
					>
						{
							videoplay == null ?
								<div className="wrapper">
									<div className="video-main">
										<div className="promo-video">
											<div className="waves-block">
												<div className="waves wave-1"></div>
												<div className="waves wave-2"></div>
												<div className="waves wave-3"></div>
											</div>
										</div>
										<a href="javascript:void(0)" className="video video-popup mfp-iframe"><i className="fa fa-play"></i></a>
									</div>
								</div>
								: <iframe class="youtubevideo" src={uacorporate?.youTubeVidio} title="YouTube video player" autoplay frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
						}
					</div>
				</div>
			</div>
			<div ref={elementRef} className='foundermessage-main' data-aos="fade-up" data-aos-duration="500">
				<div className='foundermessage-line'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='foundermessage-div'>
									<div className='foundermessage-leftdiv'>
										<h3>{aufoundermessage?.heading}</h3>
										<p>{aufoundermessage?.text1}</p>
										<p>{aufoundermessage?.text2}</p>
										<h4 dangerouslySetInnerHTML={{ __html: aufoundermessage?.subHeading }}></h4>
									</div>
									<div className='foundermessage-rightdiv'>
										<img src={aufoundermessage?.founderImg} alt={aufoundermessage?.imgAlt} loading='lazy' />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='auvalues-main' data-aos="fade-up" data-aos-duration="500">
				<div className='auvalues-auvaluesline'>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<h3>{auvalues?.heading}</h3>
						</div>
						<div className='col-xl-6 col-lg-6'>
							<img src={show} className='mapmainclass' alt={auvalues?.accordionImg[0]?.alt} loading='lazy' />
						</div>
						<div className='col-xl-6 col-lg-6'>
							<div className="accordion" id="accordionExample">
								<div className="accordion-item" onClick={() => handleAccordionClick(0)}>
									<h2 className="accordion-header" id={auvalues?.accordionItem[0]?.uniqueHeadingId}>
										<button className="accordion-button" type="button" data-related-map={auvalues?.accordionItem[0]?.dataRelatedMap} data-bs-toggle="collapse" data-bs-target={`#${auvalues?.accordionItem[0]?.uniqueTabId}`} aria-expanded="true" aria-controls={auvalues?.accordionItem[0]?.uniqueTabId}>
											{auvalues?.accordionItem[0]?.heading}
										</button>
									</h2>
									<div id={auvalues?.accordionItem[0]?.uniqueTabId} className="accordion-collapse collapse show" aria-labelledby={auvalues?.accordionItem[0]?.uniqueHeadingId} data-bs-parent="#accordionExample">
										<div className="accordion-body">
											<p dangerouslySetInnerHTML={{ __html: auvalues?.accordionItem[0]?.text }}></p>
										</div>
									</div>
								</div>
								{auvalues?.accordionItem?.map((element, i) => {
									if (i > 0) {
										return <div key={i} className="accordion-item" onClick={() => handleAccordionClick(i)}>
											<h2 className="accordion-header" id={element?.uniqueHeadingId}>
												<button className="accordion-button collapsed" type="button" data-related-map={element?.dataRelatedMap} data-bs-toggle="collapse" data-bs-target={`#${element?.uniqueTabId}`} aria-expanded="false" aria-controls={element?.uniqueTabId}>
													{element?.heading}
												</button>
											</h2>
											<div id={element?.uniqueTabId} className="accordion-collapse collapse" aria-labelledby={element?.uniqueHeadingId} data-bs-parent="#accordionExample">
												<div className="accordion-body">
													<p dangerouslySetInnerHTML={{ __html: element?.text }}></p>
												</div>
											</div>
										</div>
									}
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='testimonial-main'>
				<Swiper
					slidesPerView={1}
					spaceBetween={0}
					loop={true}
					centeredSlides={true}
					grabCursor={false}
					noSwiping={true}
					noSwipingClass={'swiper-no-swiping'}
					navigation={{
						nextEl: ".testimonials-swiper-button-next",
						prevEl: ".testimonials-swiper-button-prev",
						disabledClass: "swiper-button-disabled",
					}}
					modules={[Navigation]}
					className="testimonial-left"
				>
					{autestimonial?.map((element, i) => {
						return <SwiperSlide className="swiper-no-swiping" key={i}>
							{appendVideo && <ReactPlayer className='themeplayone' url={element?.url} allowFullScreen />}
						</SwiperSlide>
					})
					}
				</Swiper>
				<Swiper
					slidesPerView={1}
					spaceBetween={0}
					loop={true}
					centeredSlides={true}
					noSwiping={true}
					noSwipingClass={'swiper-no-swiping'}
					grabCursor={false}
					navigation={{
						nextEl: ".testimonials-swiper-button-next",
						prevEl: ".testimonials-swiper-button-prev",
						disabledClass: "swiper-button-disabled",
					}}
					modules={[Navigation]}
					className="testimonial-right"
				>
					{autestimonial?.length > 0 && autestimonial.map((element, i) => (
						<SwiperSlide className="swiper-no-swiping" key={i}>
							<p>{element?.text}</p>
							<div className='testimonial-rightone'>
								<h4>{element?.name}</h4>
							</div>
						</SwiperSlide>
					))}
					<div className='testimonials-swiper-button'>
						<div className="testimonials-swiper-button-prev">
							<svg xmlns="http://www.w3.org/2000/svg" height="45" viewBox="0 -960 960 960" width="45">
								<path d="M396.154-267.692 183.846-480l212.308-212.308 28.308 28.77L260.923-500h515.231v40H260.923l163.539 163.538-28.308 28.77Z" />
							</svg>
						</div>
						<div className="testimonials-swiper-button-next">
							<svg xmlns="http://www.w3.org/2000/svg" height="45" viewBox="0 -960 960 960" width="45">
								<path d="m547.692-267.692-28.307-28.77L682.923-460H200v-40h482.923L519.385-663.538l28.307-28.77L760-480 547.692-267.692Z" />
							</svg>
						</div>
					</div>
				</Swiper>

			</div>
			<div className='inthenews-main' data-aos="fade-up" data-aos-duration="500">
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='inthenewsdivtop'>
								<h3>In the News</h3>
								<a href='/news'>View All <img src='/websiteimages/right-arrow.png' alt='' /></a>
							</div>
						</div>
					</div>
				</div>
				<div className='newsslider container'>

					<Swiper
						spaceBetween={20}
						loop={true}
						// centeredSlides={true}
						breakpoints={{
							0: {
								slidesPerView: 1,
							},
							768: {
								slidesPerView: 3,
							}
						}}
						className='offers'
					>
						<SwiperSlide>
							<div className='newsdiv'>
								<a rel="noreferrer" href='https://realty.economictimes.indiatimes.com/news/industry/survey-soon-circle-rates-in-noida-to-be-revised-after-three-years/102819803' target='_blank'>
									<img src='/websiteimages/news-img1.jpg' alt='News' loading='lazy' />
									<p>Survey soon, circle rates in Noida to be revised after three years</p>
									<h4>Read  More</h4>
								</a>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className='newsdiv'>
								<a rel="noreferrer" href='https://realty.economictimes.indiatimes.com/news/rera/register-projects-with-rera-safeguard-interests-of-buyers-up-rera-chairman-to-promoters/102962641' target='_blank'>
									<img src='/websiteimages/news-img2.jpg' alt='News' loading='lazy' />
									<p>Register projects with RERA, safeguard interests of buyers: UP-RERA chairman to promoters</p>
									<h4>Read  More</h4>
								</a>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className='newsdiv'>
								<a rel="noreferrer" href='https://realty.economictimes.indiatimes.com/news/industry/noida-may-allot-plots-via-draw-of-lots-interviews-not-e-auction/104291740' target='_blank'>
									<img src='/websiteimages/news-img3.jpg' alt='News' loading='lazy' />
									<p>Noida may allot plots via draw of lots, interviews, not e-auction</p>
									<h4>Read  More</h4>
								</a>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
			<Footer />
		</React.Fragment>
	)
}

export default Mainpage