import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useFormik } from 'formik';
import { careerformSchema } from '../schemas';
import { fetchCareerForm } from '../controler/career/Career';
import { useNavigate } from 'react-router-dom';
import Getintouch from './Getintouch';
import Metatag from './Metatag';

const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  categoryType: "",
  // resumePdf: null,
}

function Career() {

  const navigate = useNavigate();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema: careerformSchema,
    onSubmit: async (values, action) => {
      const data = await fetchCareerForm(values);
      action.resetForm();
      navigate('/thankyou')
    }
  })
  return (
    <>
    <Metatag 
    link="career"
    title="Join AU Real Estate: Explore Career Opportunities"
    description="Discover career opportunities at AU Real Estate. Explore our vacancies and join a dynamic team dedicated to real estate excellence"
    />
      <Header />
      <Getintouch />
      <div className='aurealestate-innerbanner' data-aos="fade-up">
        <img src='/websiteimages/career-banner.jpg' alt='career banner' />
        <div className='aurealestate-content'>
          <h1>Career Opportunities</h1>
        </div>
      </div>
      <div className='career-main'>
        <div className='breadcrum-div'>
          <div className='container'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Career</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className='career-div'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2>Join us now</h2>
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='form-group'>
                        <input type='text' className='form-control' placeholder='First Name*'
                          name='firstName'
                          autoComplete='off'
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                        {errors.firstName && touched.firstName ? <p className='form-errors'>{errors.firstName}</p> : null}
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='form-group'>
                        <input type='text' className='form-control' placeholder='Last Name*'
                          name='lastName'
                          autoComplete='off'
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                        {errors.lastName && touched.lastName ? <p className='form-errors'>{errors.lastName}</p> : null}
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='form-group'>
                        <input type='text' maxLength={10} className='form-control' placeholder='Mobile No.*'
                          name='phone'
                          autoComplete='off'
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                        {errors.phone && touched.phone ? <p className='form-errors'>{errors.phone}</p> : null}
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='form-group'>
                        <input type='text' className='form-control' placeholder='E-mail Address*'
                          name='email'
                          autoComplete='off'
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur} />
                        {errors.email && touched.email ? <p className='form-errors'>{errors.email}</p> : null}
                      </div>
                    </div>
                    <div className='col-lg-4 col-sm-6'>
                      <div className='form-group'>
                        <select className='form-control'
                          name='categoryType'
                          value={values.categoryType}
                          onChange={handleChange}
                          onBlur={handleBlur}>
                          <option>Choose Job Category</option>
                          <option>Accounts</option>
                          <option>CRM</option>
                          <option>Facility Management</option>
                          <option>Finance</option>
                          <option>Human Resources</option>
                          <option>Information Technology</option>
                          <option>Legal</option>
                          <option>Marketing</option>
                          <option>Purchase</option>
                          <option>Sales</option>
                          <option>Site Engineer</option>
                        </select>
                        {errors.categoryType && touched.categoryType ? <p className='form-errors'>{errors.categoryType}</p> : null}
                      </div>
                    </div>
                    <div className='col-lg-12 text-center'>
                      <button type='submit' className='btn-style'>Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Career