import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useFormik } from 'formik';
import { contactformSchema } from '../schemas';
import { fetchContactForm } from '../controler/contactform/Contactform';
import { useNavigate, useLocation } from 'react-router-dom';
import Getintouch from './Getintouch';
import Metatag from './Metatag';
import { handleKeyPressDigits, handleKeyPressFirstName, handleKeyPressEmail } from "../validations";

function Contactus() {

    const query = new URLSearchParams(useLocation().search);
    const utm_source = query.get("utm_source");
    const utm_medium = query.get("utm_medium");
    const utm_campaign = query.get("utm_campaign");
    const utm_content = query.get("utm_content");
    const utm_term = query.get("utm_term");

    const initialValues = {
        fullName: "",
        type: "",
        phone: "",
        email: "",
        comment: "",
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        utm_content: utm_content,
        utm_term: utm_term,
    }

    const navigate = useNavigate();
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues,
        validationSchema: contactformSchema,
        onSubmit: async (values, action) => {
            const data = await fetchContactForm(values);
            action.resetForm();
            navigate('/thankyou')
        }
    })
    return (
        <>
            <Metatag
                link="contactus"
                title="Contact AU Real Estate for Real Estate Solutions"
                description="Reach out to AU Real Estate for all your real estate inquiries and solutions. Contact us today to explore our range of property services."
            />
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                {/* <video src='/websiteimages/banner-video.mp4' autoPlay loop muted /> */}
                <img src='/websiteimages/contact-s-banner.jpg' alt='' />
                <div className='aurealestate-content'>
                    <h1>Contact Us</h1>
                </div>
            </div>
            <div className='findushere-main'>
                <div className='breadcrum-div'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='container findusheretopdiv'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-4'>
                            <h2>Write to Us</h2>
                            <p>We are your trusted partner for quality real estate services. To know more please write to us.</p>
                        </div>
                        <div className='offset-xl-1 col-xl-6 col-lg-8'>
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <input type='text' className='form-control' placeholder='Full Name*' 
                                            name='fullName'
                                            autoComplete='off'
                                            value={values.fullName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyPressFirstName}
                                            />
                                            {errors.fullName && touched.fullName ? <p className='form-errors'>{errors.fullName}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <select className='form-control'
                                                name='type'
                                                value={values.type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option>Choose Business Vertical</option>
                                                <option>Commercial</option>
                                                <option>Residences</option>
                                                <option>Services</option>
                                                <option>Joint Ventures</option>
                                                <option>Advisory</option>
                                            </select>
                                            {errors.type && touched.type ? <p className='form-errors'>{errors.type}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <input type='text' maxLength={10} className='form-control' placeholder='Mobile No.*' 
                                            name='phone'
                                            autoComplete='off'
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyPressDigits}
                                            />
                                            {errors.phone && touched.phone ? <p className='form-errors'>{errors.phone}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='form-group'>
                                            <input type='text' className='form-control' placeholder='E-mail Address*' 
                                            name='email'
                                            autoComplete='off'
                                            value={values.errors}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyPressEmail}
                                            />
                                            {errors.email && touched.email ? <p className='form-errors'>{errors.email}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='form-group'>
                                            <textarea className='form-control textareatag' placeholder='Write Your Comments*'
                                                name='comment'
                                                autoComplete='off'
                                                value={values.comment}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>

                                            </textarea>
                                            {errors.comment && touched.comment ? <p className='form-errors'>{errors.comment}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <button type='submit' className='btn-style'>Submit</button>
                                    </div>
                                </div>
                                <input type='text' hidden name='utm_source' value={values.utm_source} />
                                <input type='text' hidden name='utm_medium' value={values.utm_medium} />
                                <input type='text' hidden name='utm_campaign' value={values.utm_campaign} />
                                <input type='text' hidden name='utm_content' value={values.utm_content} />
                                <input type='text' hidden name='utm_term' value={values.utm_term} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='findusherediv'>
                <div className='findushereline'></div>
                <div className='container'>
                    <h2>Find Us Here</h2>
                    <div className='findusherecontact'>
                        <div className='findusherediv-left'>
                            <h4>AU Real Estate</h4>
                            <p>10, Bharat Bhawan, New Rajdhani Enclave, Vikas Marg, New Delhi 110092</p>
                            <h5>Follow Us on</h5>
                            <ul>
                                <li><a href='https://www.facebook.com/aurealestategroup/' target='_blank' rel="noreferrer"><img src='/websiteimages/facebook-edit.png' alt='' /></a> </li>
                                <li><a href='https://www.linkedin.com/company/aurealestategroup/' target='_blank' rel="noreferrer"><img src='/websiteimages/linkedin-edit.png' alt='' /></a></li>
                                <li><a href='https://www.instagram.com/aurealestategroup/' target='_blank' rel="noreferrer"><img src='/websiteimages/instagram-edit.png' alt='' /></a></li>
                            </ul>
                        </div>
                        <div className='findusherediv-right'>
                            <iframe title='map-title' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.5946750423514!2d77.2958956!3d28.641907699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfca9f14f5783%3A0x2213488d83c0b87d!2sAgarwal%20Associates%20Group!5e0!3m2!1sen!2sin!4v1697273134615!5m2!1sen!2sin" className='mapmap' allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contactus