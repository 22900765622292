import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageNotFound from './404';
import Thankyou from './Thankyou';
import Commingsoon from './Commingsoon';
// import Index from './componentsfamilyhub/Familyhub';
import Familyhub from './componentsfamilyhub/Familyhub';
import ThankyouFamilyHub from './Thankyoufamilyhub';
import Mainpage from './homepage/Mainpage';
import Aboutus from './homepage/Aboutus';
import Newspage from './homepage/Newspage';
import Contactus from './homepage/Contactus';
import Commingsoonpage from './homepage/Commingsoonpage';
import Privacypolicy from './homepage/Privacypolicy';
import Terms from './homepage/Terms';
import Disclaimer from './homepage/Disclaimer';
import Advisorycomingsoon from './homepage/Advisorycomingsoon';
import Servicecomingsoon from './homepage/Servicecomingsoon';
import Jointcomingsoon from './homepage/Jointcomingsoon';
import Career from './homepage/Career';
import Service from './homepage/Service';
import Advisory from './homepage/Advisory';
import Jointventures from './homepage/Joint-ventures';
import Thankyoumain from './Thankyou-main';
import Blogs from './homepage/Blog';
import { BlogListDetails } from './local-api/blogsListing'
// import Residences from './homepage/Residences';
// import Commercial from './homepage/Commercial';
import Blogdetail from './homepage/Blog-detail';
import Blogdetail2 from './homepage/Blog-detail2';
import Blogdetail3 from './homepage/Blog-detail3';
import Blogdetail5 from './homepage/Blog-detail5';
import Blogdetail6 from './homepage/Blog-detail6';
import Blogdetail7 from './homepage/Blog-detail7';
import Blogdetail8 from './homepage/Blog-detail8';
import Blogdetail9 from './homepage/Blog-detail9';
import Blogdetail10 from './homepage/Blog-detail10';
import Blogdetail11 from './homepage/Blog-detail11';
import Blogdetail12 from './homepage/Blog-detail12';
import Blogdetail13 from './homepage/Blog-detail13';
import Blogdetail14 from './homepage/Blog-detail14';
import Blogdetail15 from './homepage/Blog-detail15';
import Blogdetail16 from './homepage/Blog-detail16';
import Blogdetail17 from './homepage/Blog-detail17';
import Blogdetail18 from './homepage/Blog-detail18';
import Blogdetail19 from './homepage/Blog-detail19';
import Blogdetail20 from './homepage/Blog-detail20';
import Blogdetail21 from './homepage/Blog-detail21';
import Blogdetail22 from './homepage/Blog-detail22';
import Blogdetail23 from './homepage/Blog-detail23';
import Blogdetail24 from './homepage/Blog-detail24';
import Blogdetail25 from './homepage/Blog-detail25';
import Blogdetail26 from './homepage/Blog-detail26';
import Blogdetail27 from './homepage/Blog-detail27';
import Blogdetail28 from './homepage/Blog-detail28';
import Blogdetail29 from './homepage/Blog-detail29';
import Blogdetail30 from './homepage/Blog-detail30';
import Blogdetail31 from './homepage/Blog-detail31';
import Blogdetail32 from './homepage/Blog-detail32';

const root = ReactDOM.createRoot(document.getElementById('root'));

const url = BlogListDetails?.BlogList?.map((element) => element?.link)

const changeRoute = (() => {
  if (url.find((m)=> ("/" + m) == window.location.pathname)) {
    window.location.href=`/blogs${window.location.pathname}`
  }
})

changeRoute();

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Mainpage />} />
      <Route path='*' element={<PageNotFound />} />
      <Route path='/commingsoon' element={<Commingsoon />} />
      <Route path='/arihant-enclave' element={<App />} />
      <Route path='/thank-you' element={<Thankyou />} />
      <Route path='/family-hub' element={<Familyhub />} />
      <Route path='/thank-you-family-hub' element={<ThankyouFamilyHub />} />
      <Route path='/aboutus' element={<Aboutus />} />
      <Route path='/news' element={<Newspage />} />
      <Route path='contactus' element={<Contactus />} />
      <Route path='contactus' element={<Contactus />} />
      <Route path='commingsoonpage' element={<Commingsoonpage />} />
      <Route path='privacy-policy' element={<Privacypolicy />} />
      <Route path='disclaimer' element={<Disclaimer />} />
      <Route path='terms' element={<Terms />} />
      <Route path='advisorycomingsoon' element={<Advisorycomingsoon />} />
      <Route path='servicecomingsoon' element={<Servicecomingsoon />} />
      <Route path='jointcomingsoon' element={<Jointcomingsoon />} />
      <Route path='career' element={<Career />} />
      <Route path='service' element={<Service />} />
      <Route path='advisory' element={<Advisory />} />
      <Route path='joint-ventures' element={<Jointventures />} />
      <Route path='thankyou' element={<Thankyoumain />} />
      {/* <Route path='residences' element={<Residences />} /> */}
      {/* <Route path='commercial' element={<Commercial />} /> */}
      <Route path='blogs' element={<Blogs />} />
      <Route path='blogs/examining-indias-latest-real-estate-budget' element={<Blogdetail />} />
      <Route path='blogs/exploring-2024-trends-in-real-estate-market-in-india' element={<Blogdetail2 />} />
      <Route path='blogs/key-considerations-for-purchasing-residential-plots-in-ghaziabad' element={<Blogdetail3 />} />
      <Route path='blogs/exploring-essential-drivers-in-real-estate-growth-and-investment' element={<Blogdetail5 />} />
      <Route path='blogs/what-makes-investing-in-residential-property-in-ghaziabad-a-smart-choice' element={<Blogdetail6 />} />
      <Route path='blogs/wealth-building-101-a-guide-to-choosing-the-right-property-investment-company' element={<Blogdetail7 />} />
      <Route path='blogs/community-connectivity-the-impact-of-location-on-commercial-spaces' element={<Blogdetail8 />} />
      <Route path='blogs/how-do-ghaziabads-residential-plots-offer-a-canvas-for-personalized-dream-homes' element={<Blogdetail9 />} />
      <Route path='blogs/beyond-the-ordinary-6-features-that-define-a-lavish-home' element={<Blogdetail10 />} />
      <Route path='blogs/how-to-choose-the-right-real-estate-project-in-ghaziabad' element={<Blogdetail11 />} />
      <Route path='blogs/what-to-expect-from-a-leading-property-investment-company' element={<Blogdetail12 />} />
      <Route path='blogs/how-a-consultant-turbocharges-your-home-buying-in-2024' element={<Blogdetail13 />} />
      <Route path='blogs/7-advantages-of-real-estate-joint-ventures-in-india' element={<Blogdetail14 />} />
      <Route path='blogs/investing-in-luxury-aditya-world-city-nh-24s-top-projects' element={<Blogdetail15 />} />
      <Route path='blogs/beyond-investment-the-cultural-and-social-impact-of-real-estate-projects-in-india' element={<Blogdetail16 />} />
      <Route path='blogs/are-government-initiatives-influencing-real-estate-investment-choices-in-india' element={<Blogdetail17 />} />
      <Route path='blogs/plotting-the-dream-design-inspiration-for-residential-plots-in-ghaziabad' element={<Blogdetail18 />} />
      <Route path='blogs/what-are-the-benefits-of-working-with-a-top-rated-property-investment-firm' element={<Blogdetail19 />} />
      <Route path='blogs/discovering-the-best-deals-on-commercial-shops-for-sale-9-important-tips' element={<Blogdetail20 />} />
      <Route path='blogs/what-impact-does-the-right-living-space-have-on-family-well-being' element={<Blogdetail21 />} />
      <Route path='blogs/why-ghaziabad-is-perfect-for-commercial-real-estate-investment' element={<Blogdetail22 />} />
      <Route path='blogs/embracing-natures-haven-exploring-local-parks-for-serenity-and-recreation' element={<Blogdetail23 />} />
      <Route path='blogs/gated-community-living-security-amenities-and-community-spirit' element={<Blogdetail24 />} />
      <Route path='blogs/home-is-where-the-heart-is-a-love-letter-to-simple-joys' element={<Blogdetail25 />} />
      <Route path='blogs/the-rise-of-smart-homes-integrating-technology-in-residential-plots-in-ghaziabad' element={<Blogdetail26 />} />
      <Route path='blogs/how-do-premium-houses-redefine-modern-living-standards' element={<Blogdetail27 />} />
      <Route path='blogs/6-qualities-that-distinguishes-best-real-estate-companies-in-india' element={<Blogdetail28 />} />
      <Route path='blogs/why-use-a-real-estate-advisor-whats-in-it-for-you' element={<Blogdetail29 />} />
      <Route path='blogs/the-psychology-of-space-how-your-residential-plot-impacts-mental-well-being' element={<Blogdetail30 />} />
      <Route path='blogs/the-soul-of-the-space-adding-personality-to-residential-plot-interiors' element={<Blogdetail31 />} />
      <Route path='blogs/top-7-reasons-to-consider-investing-in-commercial-property' element={<Blogdetail32 />} />
    </Routes>
  </BrowserRouter>
);