import React from 'react'
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import { useEffect } from 'react'
import { useFormik } from 'formik';
import { fetchGetInTouch } from '../controler/get-in-touch/getintouch';
import { useNavigate, useLocation, } from 'react-router-dom';
import { getintouchFormSchema } from "./../schemas";

function Getintouch() {

    const navigate = useNavigate()
    const query = new URLSearchParams(useLocation().search);
    const utm_source = query.get("utm_source");
    const utm_medium = query.get("utm_medium");
    const utm_campaign = query.get("utm_campaign");
    const utm_content = query.get("utm_content");
    const utm_term = query.get("utm_term");
    const page = useLocation().pathname;

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        // pincode :"",
        comment: "",
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        utm_content: utm_content,
        utm_term: utm_term,
        page: page
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: getintouchFormSchema,
        onSubmit: async (values, action) => {
            const data = await fetchGetInTouch(values);
            action.resetForm();
            navigate('/thankyou')
        }

    })

    useEffect(() => {
        $(document).ready(function () {
            $('.gitintouch').click(function () {
                $('.sidebar-contact').toggleClass('active')
                $('.gitintouch').toggleClass('active')
            })
        })

        $(document).ready(function () {
            $('.enquire-product').click(function () {
                $('.sidebar-contact').toggleClass('active')
                $('.gitintouch').toggleClass('active')
            })
        })

        $(document).ready(function () {
            $(".toggleclose-icon").click(function () {
                $(".sidebar-contact").removeClass('active')
                $('.gitintouch').addClass('active')
            })
        })
    }, [])
    return (
        <>
            <div className='sidebar-contact'>
                <div className='gitintouch quickconnecticonimage active'>
                    <p>Get In Touch</p>
                </div>
                <img src="/websiteimages/cross-icon.png" className="crossiconimage toggleclose-icon" alt="" width="20" height="20" />
                <div className='scroll'>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <input type='text' className='form-control' placeholder='First Name*'
                                name='firstName'
                                autoComplete='off'
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            {errors.firstName && touched.firstName ? <p className="form-errors">{errors.firstName}</p> : null}
                        </div>
                        <div className='form-group'>
                            <input type='text' className='form-control' placeholder='Last Name*'
                                name='lastName'
                                autoComplete='off'
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            {errors.lastName && touched.lastName ? <p className="form-errors">{errors.lastName}</p> : null}
                        </div>
                        <div className='form-group'>
                            <input type='text' className='form-control' placeholder='Email ID*'
                                name='email'
                                value={values.email}
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            {errors.email && touched.email ? <p className='form-errors'>{errors.email}</p> : null}
                        </div>
                        <div className='form-group'>
                            <input type='text' maxLength={10} className='form-control' placeholder='Mobile No*'
                                name='phone'
                                value={values.phone}
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone ? <p className='form-errors'>{errors.phone}</p> : null}
                        </div>
                        {/* <div className='form-group'>
                            <input type='text' maxLength={6} className='form-control' placeholder='Pincode'
                            name='pincode'
                            value={values.pincode}
                            autoComplete='off'
                            onBlur={handleBlur}
                            onChange={handleChange}/>
                            {errors.pincode && touched.pincode ? <p className='form-errors'>{errors.pincode}</p> : null}
                        </div> */}
                        <div className='form-group'>
                            <textarea className='form-control' placeholder='Write Your Comments*'
                                name='comment'
                                autoComplete='off'
                                value={values.comment}
                                onChange={handleChange}
                                onBlur={handleBlur}></textarea>
                            {errors.comment && touched.comment ? <p className='form-errors'>{errors.comment}</p> : null}
                        </div>
                        <button type='submit' className='btn-style'>Submit</button>
                        <input type='text' hidden name='page' value={values.page} />
                        <input type='text' hidden name='utm_source' value={values.utm_source} />
                        <input type='text' hidden name='utm_medium' value={values.utm_medium} />
                        <input type='text' hidden name='utm_campaign' value={values.utm_campaign} />
                        <input type='text' hidden name='utm_content' value={values.utm_content} />
                        <input type='text' hidden name='utm_term' value={values.utm_term} />
                    </form>
                </div>
            </div>
        </>
    )
}

export default Getintouch