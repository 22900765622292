import React from 'react'
import Header from './homepage/Header';
import Footer from './homepage/Footer';
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <>
            <Header />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '80vh' }}>
                <h1 style={{ paddingBottom: '1.5rem'}}>404 Not Found</h1>
                <Link to={'/'} style={{ color: 'black' }}>Go back to home &#129106;</Link>
            </div>
            <Footer />
        </>
    )
}