import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchGetInTouch = async (jsonData) => {
    const data = await fetch(`${baseUrl}/${Endpoints.getintouch}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)
  
  });
    return await data.json();
  };