import Endpoints from "../controler/Endpoints";
import { baseUrl } from "../controler/ApiHelper";

export const fetchEnquiryForm = async (jsonData) => {
    const data = await fetch(`${baseUrl}/${Endpoints.enquiryform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)

  });
    return await data.json();
};

export const fetchFamilyHubEnquiryForm = async (jsonData) => {
  const data = await fetch(`${baseUrl}/${Endpoints.familyhubenquiryform}`, {
  method: 'POST', 
  headers: {
      'Content-Type': 'application/json'
    },
  mode: 'cors', 
  body: JSON.stringify(jsonData)

});

  return await data.json();
};
