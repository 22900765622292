export const handleKeyPressFirstName = (e) => {
    const keyCode = e.keyCode || e.which;
    const allowedKeys = [8, 13, 27, 46, 37, 38, 39, 40, 32, 9, 49];
    const isAlphabetKey =
      (keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122);
  
    if (!isAlphabetKey && !allowedKeys.includes(keyCode)) {
      e.preventDefault();
    }
  };
  
  export const handleKeyPressDigits = (e) => {
    const keyCode = e.keyCode || e.which;
    const allowedKeys = [8, 13, 27, 46, 37, 38, 39, 40, 9];
    const isNumberKey =
      (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
  
    if (!isNumberKey && !allowedKeys.includes(keyCode)) {
      e.preventDefault();
    }
  };
  
  export const handleKeyPressEmail = (e) => {
    const keyCode = e.keyCode || e.which;
    const allowedKeys = [8, 13, 27, 46, 190, 37, 38, 39, 40, 9];
    const isNumberKey =
      (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
    const isAlphabetKey =
      (keyCode >= 65 && keyCode <= 90) || (keyCode >= 97 && keyCode <= 122);
  
    if (!isNumberKey && !isAlphabetKey && !allowedKeys.includes(keyCode)) {
      e.preventDefault();
    }
  };