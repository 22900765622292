// import Endpoints from "../Endpoints";
// import { baseUrl } from "../ApiHelper";

// export const fetchCareerForm = async (jsonData) => {
//   var form_data = new FormData();

// for ( var key in jsonData ) {
//     form_data.append(key, jsonData[key]);
// }
//     const data = await fetch(`${baseUrl}/${Endpoints.careerform}`, {
//     method: 'POST', 
//     headers: {
//         'Content-Type': 'multipart/form-data'
//       },
//     form_data
//   });
//     return await data.json();
//   };

import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchCareerForm = async (jsonData) => {
    const data = await fetch(`${baseUrl}/${Endpoints.careerform}`, {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
      },
    mode: 'cors', 
    body: JSON.stringify(jsonData)
  
  });
    return await data.json();
  };