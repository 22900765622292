import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

function Header() {
	const [isFixed, setIsFixed] = useState(false)
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

	useEffect(() => {
		(function () {
			document.querySelector('#navbarSideCollapse').addEventListener('click', function () {
				document.querySelector('.offcanvas-collapse').classList.toggle('open')
			})
		})()
	}, [])

	useEffect(() => {
		const handleScroll = () => {
			// Change to fixed header after scrolling 100px
			const scrollPosition = window.scrollY
			setIsFixed(scrollPosition > 500)
		}

		// Add scroll event listener
		window.addEventListener("scroll", handleScroll)

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])
	return (
		<header className="header-main" style={{ position: isFixed ? 'fixed' : 'absolute', top: 0, left: 0, zIndex: 999, width: '100%', backgroundColor: '#fff' }}>
			<nav className="navbar navbar-expand-lg" aria-label="Main navigation">
				<div className="container">
					<a className="navbar-brand" href="/">
						<img src="/websiteimages/au-logo-black.png" alt='' />
					</a>
					<button className="navbar-toggler" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
						<img src="images/toggle-icon.png" alt="" />
					</button>
					<div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link className="nav-link" ria-current="page" to="/aboutus">About Us</Link>
							</li>
							<li className="nav-item dropdown">
								<Link className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
									Projects
								</Link>
								<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
									<li className='residencesDropdown'><a className="dropdown-item" target='_blank' rel='noreferrer'>Residences</a>
										<ul className="SubDropdown dropdown-menu">
											<li><a className="SubDropdown-item dropdown-item" href="/arihant-enclave" target='_blank' rel='noreferrer'>Arihant Enclave</a></li>
											<li><a className="SubDropdown-item dropdown-item" href="/the-sunflower" target='_blank' rel='noreferrer'>The Sunflower</a></li>
										</ul>
									</li>
									<li><a className="dropdown-item" href="/family-hub" target='_blank'>Commercial</a></li>
								</ul>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/service">Services</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/advisory">Advisory</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/joint-ventures">Joint Ventures</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/blogs">Blogs</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/news">News</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/contactus">Contact Us</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	)
}

export default Header