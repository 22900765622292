import React from 'react'
import { useEffect, useState } from 'react';
import './familyhub.css'
import './familyhub-responsive.css'
import { useFormik } from 'formik';
import { familyHubEnquiryForm } from "./../schemas";
import { fetchFamilyHubEnquiryForm } from "./../controler";
import { Modal } from 'antd';
import Cookies from "js-cookie";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { fetchFamilyHubDataByMonth, fetchFamilyHubYear } from '../controler/arihant-enclave/Arihantenclave';
import { imageUrl } from '../controler/ApiHelper';
// import Moment from 'react-moment';
import moment from 'moment/moment';
import Metatag from '../homepage/Metatag';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

function Familyhub() {
	const searchParams = useSearchParams();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [familyhubyear, setFamilyHubYear] = useState([]);
	const [familyhubdatabymonth, setFamilyHubDataByMonth] = useState({});
	const [selectedDrpYear, setSelectedDrpYear] = useState();
	const carouselRef = React.useRef(null);

	const query = new URLSearchParams(useLocation().search);
	const utm_source = query.get("utm_source");
	const utm_medium = query.get("utm_medium");
	const utm_campaign = query.get("utm_campaign");
	const utm_content = query.get("utm_content");
	const utm_term = query.get("utm_term");

	const initialValues = {
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		comment: "",
		termsandcondition: false,
		utm_source: utm_source,
		utm_medium: utm_medium,
		utm_campaign: utm_campaign,
		utm_content: utm_content,
		utm_term: utm_term,
	};

	const navigate = useNavigate()
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
		initialValues: initialValues,
		validationSchema: familyHubEnquiryForm,
		onSubmit: async (values, action) => {
			// let getQueryParams = searchParams.get();
			let fdata = { ...values };
			for (let entry of searchParams.entries()) {
				fdata[entry[0]] = entry[1];
			}
			const data = await fetchFamilyHubEnquiryForm(fdata)
			action.resetForm();
			navigate('/thank-you-family-hub')
		}
	})

	useEffect(() => {

		let acceptedDisclamer = Cookies.get("disclaimer-accepted");

		if (!acceptedDisclamer) {
			setIsModalOpen(true);
		}

		let nav = document.querySelector(".navbar");
		window.onscroll = function () {
			if (document.documentElement.scrollTop > 20) {
				nav.classList.add('header-scrolled')
			} else {
				nav.classList.remove('header-scrolled')
			}
		};

		let navbar = document.querySelectorAll(".nav-link");
		let navCollapse = document.querySelector(".navbar-collapse.collapse");
		navbar.forEach(function (a) {
			a.addEventListener("click", function () {
				navCollapse.classList.remove("show");
			})
		})


		/**
 * Scroll top button
 */
		let scrollTop = document.querySelector('.scroll-top');

		function toggleScrollTop() {
			if (scrollTop) {
				window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
			}
		}
		scrollTop.addEventListener('click', (e) => {
			e.preventDefault();
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		});

		window.addEventListener('load', toggleScrollTop);
		document.addEventListener('scroll', toggleScrollTop);

		async function familyhubYearr() {
			const data = await fetchFamilyHubYear();
			setFamilyHubYear(data.data);
			familyhubdatabymonthFunc(data.data[data.data.length - 1].year);
		}
		familyhubYearr();
	}, [])

	async function familyhubdatabymonthFunc(currYear) {
		// let option_selected = '2024-03';
		setSelectedDrpYear(currYear);
		const data = await fetchFamilyHubDataByMonth(currYear);

		let t_data = data.data;
		setFamilyHubDataByMonth(() => ({ data: t_data }));
	}

	function saveCookie() {

		Cookies.set("disclaimer-accepted", "true", {
			expires: 180,
		});
		setIsModalOpen(false);
	}

	async function getData(e) {

		setFamilyHubDataByMonth();
		let val = { "month": "" }
		val.month = e.target.value;
		setSelectedDrpYear(e.target.value);
		const dataa = await fetchFamilyHubDataByMonth(e.target.value);
		setFamilyHubDataByMonth(() => ({ data: dataa.data }));
	}

	return (
		<>
			<Metatag
				link="family-hub"
				title="Explore Commercial Properties for Sale | AU Real Estate"
				description="Discover prime commercial properties for sale at AU Real Estate. Find the perfect commercial space to suit your business needs and goals."
			/>
			<Modal
				className="modalone"
				open={isModalOpen}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				closeIcon={null}
			>
				<h1 style={{ position: 'absolute', zIndex: -100 }}>Explore Commercial Properties for Sale</h1>
				<h2>Disclaimer</h2>
				<p>Thank you for visiting our website </p>
				<p> This disclaimer will be applicable to the website. By using or accessing the website you agree with the
					disclaimer
					without any qualification or limitation. The company reserves the right to add, alter or delete material
					from
					the website at any time and may, at any time, revise these terms without notifying you. You are bound by any
					such
					amendments and the company therefore advise that you periodically visit this page to review the current
					terms. </p>
				<p>The websites and all its content are provided on an "as is" and "as available" basis. No information given
					under
					this website creates a warranty or expand the scope of any warranty that cannot be claimed under the
					applicable
					laws. Your use of the website is solely at your own risk. This website is for guidance only. It does not
					constitute
					part of an offer or contract. Design & specifications are subject to change without any prior notice.
					Computer
					generated images are the artist's impression and are only an indicative of the actual designs. </p>
				<p>The contents are being modified in terms of the stipulations / recommendations under the Real Estate
					Regulation
					Act, 2016 and rules made thereunder ("RERA") and accordingly may not be fully in line thereof as of date.
					You are therefore required to verify all the details, including area, amenities, services, terms of sales
					and
					payments and other relevant terms independently with the company prior to concluding any decision for buying
					any
					unit(s) in any of the said projects. Till such time the details are fully updated, the said information will
					not
					be construed as an advertisement. To find out more about a project / development, please telephone our sales
					centre or visit our sales office during office hours and speak to any of our sales staff.
				</p>

				<button className="btnmain" onClick={saveCookie}>Continue</button>
			</Modal>
			<header className="header-wrapper">
				<nav className="navbar navbar-expand-lg fixed-top">
					<div className="container">
						<a className="navbar-brand" href="/">
							<img src="/familyhubimg/images/Family-Hub-logo.png" alt='' />
						</a>
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<img src="images/toggle-icon.png" alt="" />
						</button>
						<div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
							<ul className="navbar-nav">
								<li className="nav-item">
									<a className="nav-link active" aria-current="page" href="#home">Overview</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#amentities">Amentities</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#thegreenvista">The Green Vista</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#layoutplan">Layout Plan</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#location">Location</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#gallery">Gallery</a>
								</li>

							</ul>
							<a href="/familyhubimg/images/Family_Hub_eBrochure_revised_copy.pdf" target='_blank' rel="noopener noreferrer" className="downloadbrochurefamily">Download Brouchure</a>
						</div>
					</div>
				</nav>

			</header>
			<section id="home" className="herofamily">
				<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-interval="5000">
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img src="/familyhubimg/images/main-banner1.png" alt="" className='d-md-block d-none' />
							<img src="/familyhubimg/images/mobile-banner1.jpg" alt="" className='d-md-none d-block' />
						</div>
						<div className="carousel-item">
							<img src="/familyhubimg/images/main-banner2.jpg" alt="" className='d-md-block d-none' />
							<img src="/familyhubimg/images/mobile-banner2.jpg" alt="" className='d-md-none d-block' />
						</div>
					</div>
					{/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Next</span>
				</button> */}
				</div>
				{/* <img src="/familyhubimg/images/main-banner1.png" alt="" /> */}
				<div className="container">
					<form onSubmit={handleSubmit}>
						<div className="formdivfamily">
							<h2>Enquire Now</h2>
							<div className="row">
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="name1" className="form-control" id="name1" placeholder="your first name"
												name="firstname"
												autoComplete="off"
												value={values.firstname}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="name1">First Name</label>
										</div>
										{errors.firstname && touched.firstname ? <p className="form-errors">{errors.firstname}</p> : null}
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="name1" className="form-control" id="name2" placeholder="your last name"
												name="lastname"
												autoComplete="off"
												value={values.lastname}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="name2">Last Name</label>
										</div>
										{errors.lastname && touched.lastname ? <p className="form-errors">{errors.lastname}</p> : null}
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="text" maxLength={10} className="form-control" id="phone" placeholder="your phone number"
												name="phone"
												autoComplete="off"
												value={values.phone}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="phone">Phone Number</label>
										</div>
										{errors.phone && touched.phone ? <p className="form-errors">{errors.phone}</p> : null}
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<div className="form-floating">
											<input type="email" className="form-control" id="email" placeholder="your email id"
												name="email"
												autoComplete="off"
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur} />
											<label htmlFor="email">Email ID</label>
										</div>
										{errors.email && touched.email ? <p className="form-errors">{errors.email}</p> : null}
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<div className="form-floating">
											<textarea type="text" name="comment" className="form-control" placeholder="Message"
												value={values.comment}
												onChange={handleChange}
												onBlur={handleBlur}></textarea>
											<label htmlFor="Message">Message</label>
											{errors.comment && touched.comment ? <p className="form-errors">{errors.comment}</p> : null}
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" name="termsandcondition" id="flexCheckDefault"
											value={values.termsandcondition}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label className="form-check-label" htmlFor="flexCheckDefault">
											I accept the privacy policy and terms of use
										</label>
										{errors.termsandcondition && touched.termsandcondition ? <p className="form-errors">{errors.termsandcondition}</p> : null}
									</div>
								</div>
								<div className="col-12 text-center">
									<button type="submit" className="btnmainfamily">Submit</button>
								</div>
								<input type='text' hidden name='utm_source' value={values.utm_source} />
								<input type='text' hidden name='utm_medium' value={values.utm_medium} />
								<input type='text' hidden name='utm_campaign' value={values.utm_campaign} />
								<input type='text' hidden name='utm_content' value={values.utm_content} />
								<input type='text' hidden name='utm_term' value={values.utm_term} />
							</div>
						</div>
					</form>
				</div>
			</section>
			<section className="aboutmainfamily">
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-10 mx-auto text-center">
							<h2
							>Nestled within the verdant landscapes of Aditya World City in Ghaziabad, NH 24,
								a vibrant community hub that elevates your quality of life.</h2>
							<p>Whether you're in search of the latest fashion trends, culinary delights, or simply a tranquil
								place to
								unwind amidst nature, Family Hub caters to your diverse lifestyle needs.This vibrant shopping
								and
								entertainment hub offers a unique and refreshing experience, positioned against the backdrop of
								an expansive
								Green Vista.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="amenitiesmainfamily" id="amentities">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-12">
							<div className='AmenitiesSection'>
								<h2>Amenities</h2>
								<div className='Amenities-button'>
									<div className='Amenities-swiper-button-prev'>
										<i class='fa fa-chevron-left'></i>
									</div>
									<div className='Amenities-swiper-button-next'>
										<i class='fa fa-chevron-right'></i>
									</div>
								</div>
							</div>
							<Swiper
								slidesPerView={3}
								spaceBetween={10}
								loop={false}
								autoplay={true}
								navigation={{
									nextEl: '.Amenities-swiper-button-next',
									prevEl: '.Amenities-swiper-button-prev',
								}}
								breakpoints={{
									0: {
										slidesPerView: 1,
									},
									768: {
										slidesPerView: 2,
									},
									820: {
										slidesPerView: 3,
									}
								}}
								modules={[Navigation, Autoplay]}
							>
								<SwiperSlide>
									<img src="/familyhubimg/images/amenities-img1.jpg" alt='Performance Atrium' />
									<p>Performance Atrium</p>
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/amenities-img2.jpg" alt='Musical Fountains' />
									<p>Musical Fountains</p>
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/amenities-img3.jpg" alt='Food Outlets' />
									<p>Food Outlets</p>
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/amenities-img4.jpg" alt='Landscape Lighting' />
									<p>Landscape Lighting</p>
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/amenities-img5.jpg" alt='Open-Air Cafes' />
									<p>Open-Air Cafes</p>
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/amenities-img6.jpg" alt='Provision for Pop-up Markets' />
									<p>Provision for Pop-up Markets</p>
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/amenities-img7.jpg" alt='Seating Area Among Water Jets' />
									<p>Seating Area Among Water Jets</p>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
				</div>
			</section>
			<section className="Thegreenvistafamily" id="thegreenvista">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<Swiper
								slidesPerView={1}
								loop={false}
								autoplay={true}
								modules={[Autoplay]}
								className='greenvista-swiper'
							>
								<SwiperSlide>
									<img src="/familyhubimg/images/greenvista-img1.png" alt='The Green Vista' />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/greenvista-img2.jpg" alt='The Green Vista' />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/greenvista-img3.jpg" alt='The Green Vista' />
								</SwiperSlide>
							</Swiper>
						</div>
						<div className="col-xl-5 offset-xl-1 col-lg-6">
							<h2>The Green Vista</h2>
							<p>Positioned against the backdrop of an expansive Green Vista, Family Hub is a sanctuary of
								greenery,
								featuring mesmerizing musical fountains, meticulously crafted topiary gardens, inviting seating
								areas
								amidst water jets, and much more. Imagine the sheer joy of spending quality time with your loved
								ones,
								surrounded by lush greenery and a vibrant community. Family Hub is designed for discerning
								residents,
								well-travelled and seeking something beyond the ordinary.
							</p>
						</div>
					</div>
				</div>
			</section>
			<section className="layoutplanmainfamily" id="layoutplan">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Layout Plan</button>
									<button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Floor Plan</button>
								</div>
							</nav>
							<div className="tab-content" id="nav-tabContent">
								<div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
									<div className="row">
										<div className="col-lg-12">
											<img src="/familyhubimg/images/Layout-plan.jpg" alt='Layout Plan' />
										</div>
										<div className="col-lg-4 col-md-4">
											<h2>Layout Plan</h2>
										</div>
										<div className="offset-lg-2 col-lg-6 col-md-8">
											<ul>
												<li>Lawn</li>
												<li>Planter Court</li>
												<li>Performance Stage</li>
												<li>Musical Fountain</li>
												<li>Seating Pavilion</li>
												<li>Topiary Garden</li>
												<li>Outdoor Seating</li>
												<li>Seating Area with Water Jets</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
									<div className="row">
										<div className='col-lg-6'>
											<img src="/familyhubimg/images/Floor-img1.jpg" alt='Floor Plan' />
										</div>
										<div className='col-lg-6'>
											<img src="/familyhubimg/images/Floor-img4.jpg" alt='Floor Plan' />
										</div>
										<div className='col-lg-6'>
											<img src="/familyhubimg/images/Floor-img2.jpg" alt='Floor Plan' />
										</div>
										<div className='col-lg-6'>
											<img src="/familyhubimg/images/Floor-img3.jpg" alt='Floor Plan' />
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="constructionupdatesmainfamily">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="constructionupdatesdivfamily">
								<h2>Construction Updates</h2>
								<select onChange={getData} className="form-control">
									{familyhubyear.toReversed().map((familyhubyearr) => {
										return (
											<option value={familyhubyearr.year} selected={familyhubyearr.year === selectedDrpYear ? true : null}>
												{moment(familyhubyearr.year).format('MMM, YYYY')}</option>
										)
									})}
									{/* <option>Aug, 2023</option> */}
									{/* <option>Sep, 2023</option> */}
									{/* <option>Oct, 2023</option>
									<option>Nov, 2023</option>
									<option>Dec, 2023</option> */}
								</select>
								<div className='construction-button'>
									<div className='construction-swiper-button-prev'>
										<i class='fa fa-chevron-left'></i>
									</div>
									<div className='construction-swiper-button-next'>
										<i class='fa fa-chevron-right'></i>
									</div>
								</div>
							</div>

							{familyhubdatabymonth?.data ?
								<Swiper
									spaceBetween={10}
									loop={false}
									autoplay={{
										delay: 4000,
									}}
									navigation={{
										nextEl: '.construction-swiper-button-next',
										prevEl: '.construction-swiper-button-prev',
									}}
									breakpoints={{
										0: {
											slidesPerView: 1,
										},
										768: {
											slidesPerView: 2,
										},
										820: {
											slidesPerView: 3,
										},
									}}
									modules={[Autoplay, Navigation]}
									className="constructionSwiper"
								>
									{familyhubdatabymonth?.data ? familyhubdatabymonth?.data.map((x, index) => {
										return <SwiperSlide key={index}>
												<img src={imageUrl + x.image} alt="Construction Updates" />
										</SwiperSlide>
									}) : []}
								</Swiper>
								: ""}
						</div>
					</div>
				</div>
			</section>
			<section className='familyhubVideo'>
				<div>
					<iframe src="https://www.youtube.com/embed/eZLvt2vlXx0?si=1oF0fUf3NcASXJuF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</div>
			</section>
			<section className="catchmentsmainfamily" id="location">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h3>Catchments</h3>
						</div>
						<div className="col-lg-3 col-md-2">
							<div className="catchmentsmainleftfamily">
								<img src="/familyhubimg/images/catch-img.png" alt="AI" />
							</div>
						</div>
						<div className="col-xl-8 offset-xl-1 col-lg-9 col-md-10">
							<ul>
								<li>
									<img src="/familyhubimg/images/catch-icon1.png" alt="Wave City" />
									<p>Wave City</p>
								</li>
								<li>
									<img src="/familyhubimg/images/Aditya-lmg.png" alt="Aditya World City" />
									<p>Aditya World City</p>
								</li>
								<li>
									<img src="/familyhubimg/images/crossing-img.png" alt="Crossing Republik" />
									<p>Crossing Republik</p>
								</li>
								<li>
									<img src="/familyhubimg/images/mahagun-img.png" alt="Mahagunpuram" />
									<p>Mahagunpuram</p>
								</li>
								<li>
									<img src="/familyhubimg/images/abes-img.png" alt="ABES Engineering College" />
									<p>ABES Engineering College</p>
								</li>
								<li>
									<img src="/familyhubimg/images/ims-img.png" alt="IMS Ghaziabad" />
									<p>IMS Ghaziabad</p>
								</li>
								<li>
									<img src="/familyhubimg/images/catchment-img.png" alt="10 Lakhs+ Catchment" />
									<p>10 Lakhs+ Catchment</p>
								</li>
								<li>
									<img src="/familyhubimg/images/meeruth.png" alt="14 Lanes Delhi-Meerut Expressway" />
									<p>14 Lanes Delhi-Meerut Expressway</p>
								</li>
							</ul>
						</div>
						<div className="col-lg-12">
							<a href="https://www.google.com/maps/place/AU+Family+Hub/@28.6518959,77.4901347,17z/data=!3m1!4b1!4m6!3m5!1s0x390cf3f46811223d:0x308863dac983117!8m2!3d28.6518959!4d77.4901347!16s%2Fg%2F11kqhr2glq?entry=ttu"
								className="maplocation" target='_blank' rel='noreferrer'>Map Location <img src="/familyhubimg/images/right-arrow.png" alt='' /></a>
						</div>
					</div>
				</div>
			</section>
			<section className="Aubussinessmainfamily">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 align-items-center">
							<h3>AU Business Verticals</h3>
							<div>
								<div className="logofamily">
									<img src="/familyhubimg/images/Residences.png" alt="Residences" />
								</div>
								<div className="logofamily">
									<img src="/familyhubimg/images/Commercial.png" alt="Commercial" />
								</div>
								<div className="logofamily">
									<a href="/advisory">
										<img src="/familyhubimg/images/Advisory.png" alt="Advisory" />
									</a>
								</div>
								<div className="logofamily">
									<a href="/service">
										<img src="/familyhubimg/images/Services.png" alt="Services" />
									</a>
								</div>
								<div className="logofamily">
									<a href="/joint-ventures">
										<img src="/familyhubimg/images/Jointventures.png" alt="Jointventures" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="gallerymainfamily" id="gallery">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className='GallerySection'>
								<h2>Gallery</h2>
								<div className='Gallery-button'>
									<div className='Gallery-swiper-button-prev'>
										<i class='fa fa-chevron-left'></i>
									</div>
									<div className='Gallery-swiper-button-next'>
										<i class='fa fa-chevron-right'></i>
									</div>
								</div>
							</div>

							<Swiper
								slidesPerView={3}
								spaceBetween={20}
								loop={false}
								autoplay={true}
								navigation={{
									nextEl: '.Gallery-swiper-button-next',
									prevEl: '.Gallery-swiper-button-prev',
								}}
								breakpoints={{
									0: {
										slidesPerView: 1,
									},
									768: {
										slidesPerView: 2,
									}
								}}
								modules={[Navigation, Autoplay]}
								className='GallerySwiper'
							>
								<SwiperSlide>
									<img src="/familyhubimg/images/gallery-img1.jpg" alt='Performance Atrium' />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/gallery-img2.jpg" alt='Musical Fountains' />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/gallery-img1.jpg" alt='Food Outlets' />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/gallery-img2.jpg" alt='Landscape Lighting' />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/gallery-img1.jpg" alt='Open-Air Cafes' />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/familyhubimg/images/gallery-img2.jpg" alt='Provision for Pop-up Markets' />
								</SwiperSlide>
							</Swiper>

						</div>
					</div>
				</div>
			</section>
			<section className="homeloadmainfamily aos-init aos-animate" data-aos="fade-up">
				<div className="container">
					<div className="homeloanlogodivfamily">
						<div className="row">
							<div className="col-xl-6 col-lg-5 col-md-6">
								<h3>Loan Approved <br /> From</h3>
							</div>
							<div className="col-xl-6 col-lg-7 col-md-6">
								<div className="banklogomainfamily">
									<div className="banklogodivfamily"><img src="/familyhubimg/images/hdfc-bank-logo.png" alt="hdfc bank" /></div>
									<div className="banklogodivfamily"><img src="/familyhubimg/images/icici-logo.png" alt="icici Bank" /></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='footermainfamily' id="location">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-md-4 col-12">
							<div className="footer-logofamily">
								<ul>
									<li>
										<a href='/'>
											<img src="/familyhubimg/images/au-logo-black.png" alt='AU Real Estate' />
										</a>
									</li>
									<li>
										<img src="/familyhubimg/images/aditya-logo.png" alt='Aditya' />
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-5 col-md-4 col-12">
							<h5>AU Real Estate Services Pvt. Ltd.</h5>
							<p>10, Bharat Bhawan, New Rajdhani Enclave, Vikas Marg, New Delhi 110092</p>
						</div>
						<div className="col-xl-3 col-md-4 col-12">
							<div className="contentdetailfamily">
								<ul>
									<li><a href="mailto:marketing@aurealestate.in"><img src="images/Mail-icon.png" alt="mail" /> marketing@aurealestate.in</a></li>
									<li><a href="tel:+917309999730"><img src="images/Call-icon.png" alt="call" /> +91 7309999730</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-4 col-md-4 col-12">
							<div className="social-logofamily">
								<h5>Follow Us on</h5>
								<ul>
									<li>
										<a href='https://www.facebook.com/aurealestategroup/' target='_blank' rel='noreferrer' >
											<img src="/familyhubimg/images/facebook-edit.png" alt="facebook" />
										</a>
									</li>
									<li>
										<a href='https://www.linkedin.com/company/aurealestategroup' target='_blank' rel='noreferrer'>
											<img src="/familyhubimg/images/linkedin-edit.png" alt="linkedin" />
										</a>
									</li>
									{/* <li>
										<img src="images/twitter-icon.png" alt="" />
									</li> */}
									<li>
										<a href='https://www.instagram.com/aurealestategroup/' target='_blank' rel='noreferrer'>
											<img src="/familyhubimg/images/instagram-edit.png" alt="instagram" />
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-5 col-md-4 col-12">
							<h6>Site Address:</h6>
							<p>Commiercial Plot No-1, Sector-2, Aditya World City, NH-24, Ghaziabad, Uttar Pradesh 201002</p>
						</div>
						<div className="col-xl-3 col-md-4 col-12">
							<h6>RERA Registration Number</h6>
							<p>UPRERAPRJ922146 <br />
								<b><a href="https://www.up-rera.in/index" target="_blank" rel='noreferrer'>(www.up-rera.in)</a></b>
							</p>
						</div>
					</div>
				</div>
				<div className="footer-bottomfamily">
					<div className="container">
						<div className="row">
							<div className="col-xl-9 col-md-8">
								<span>*The images shown above are for illustration purposes only and are not an exact
									representation of the product.</span>
							</div>
							<div className="col-xl-3 col-md-4 ">
								<span className='pull-right'>Copyrights . All rights reserved.</span>
							</div>
						</div>
					</div>
				</div>
			</section>
			<a href="/" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="fa fa-angle-double-up"></i></a>
		</>
	)
}

export default Familyhub